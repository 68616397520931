body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  height: 100vh;
  /*width: 100vw;*/
  /*	animation-name: wall;*/
  /*	animation-duration: 7s;*/
  /*	!*animation-iteration-count: infinite;*!*/
  /*	animation-iteration-count: 1;*/
  background: linear-gradient(145deg, rgb(68, 68, 68), rgb(0, 0, 0));
  /*background-image: url("../img/river-forest-bg.jpeg");*/
  /*background-image: url("../img/ocean-breaking-wave.jpeg");*/
  /*filter: blur(8px)*/
  /*height: 100vh;*/
  min-height: 100vh;
  max-height: 100vh;
  background-size: 100vw 200vh;
  overflow: hidden;
  /*opacity: 5%;*/
}
/*div{*/
/*	align-self: auto;*/
/*	text-align: center;*/
/*	margin: auto;*/
/*	border: 1px solid rebeccapurple;*/
/*	margin-top: 40vh;*/
/*	box-sizing: border-box;*/
/*	background-color: cadetblue;*/
/*	color: lightblue;*/
/*	content: "";*/
/*	height: 200px;*/
/*	width: 300px;*/
/*	box-shadow: 15px 15px royalblue;*/
/*	position: relative;*/
/*	animation-name: box;*/
/*  animation-duration: 1s;*/
/*  animation-iteration-count: 1;*/
/*  border-radius: 25%;*/
/*  padding-top: 5%;*/
/*  font-size: 2rem;*/
/*  mix-blend-mode: multiply;*/
/*}*/

/*div:after{*/
/*	content: "Hello World!";*/
/*}*/
/*The animation code*/
/*@keyframes box {*/
/*0%   {transform: translateY(10px);}*/
/*50% {transform: translateY(-20px);color: black;}*/
/*100% {transform: translateY(10px);}*/
/*}*/
/*@keyframes wall {*/
/*	0% {background-color: black;}*/
/*	50% {background-color: white;}*/

/*	!*100% {background-color: black;}*!*/
/*	50% {*/
/*		background: linear-gradient(145deg, #444, #000);*/
/*		filter: blur(8px)*/
/*	;}*/
/*}*/