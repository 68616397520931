#projects{
    /* margin-top: 5rem; */
    /* margin-left: 20rem; */
    display: flex;
    /* z-index: 10; */
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
}


